import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Audio from "../components/audio"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h2>So, what is a Snorph?</h2>
    <p>
      A Snorph is a mythical animal that lives in the Pacific Northwest of the US.<br/>
      It is a combination of a dolphin and a pig (don't ask us how) and can be recognized by the loud snorts it emits when tickeled.<br/>
      Click the Snorph to hear its call of mirth!
      <Audio soundName="snort">
        <div style={{maxWidth: `100px`, marginBottom: `1.45rem`}}>
          <Image index={2} />
        </div>
      </Audio>
    </p>
    <h2>About our brand</h2>
    <p>
      We try to have our brand reflect the feelings and values we've gained, since moving to the PNW.<br/>
      Therefore, our artisitic expressions are:<br/>
      <ol>
        <li>Cartoonish/comic-y</li>
        <li>Cutesy, silly</li>
        <li>Punny, snarky</li>
        <li>Irreverent, quirky</li>
        <li>Witty, clever</li>
        <li>Simple, unfussy style</li>
      </ol>
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage;
