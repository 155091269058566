import React, {useRef} from 'react';

export default ({soundName, children}) => {
  let sound = require(`../sounds/${soundName}.mp3`);
  const soundRef = useRef(null);

  const playSound = () => {
    soundRef.current.play();
  }

  return <span
    style={{outline: 0}}
    role="button"
    tabIndex={0}
    onClickCapture={playSound}
    onKeyPressCapture={playSound}
    >
      <audio ref={soundRef}>
        <track kind="captions" label={soundName} />
        <source src={sound} />
      </audio>
      {children}
  </span>
};
